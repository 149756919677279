import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    white: '#fff',
    offGray: '#b7cac4',
    black: '#000',
    darkGray: '#283330',
    background: '#edf9f6',
    extra: '#9f64ff',
    hot: '#ff603e',
    blue: '#0d8bff',
    kakaoYellow: '#fee500',
    naverGreen: '#03c75a',
    googleRed: '#ea4335',
    googleYellow: '#fbbc05',
    googleGreen: '#34a853',
    googleBlue: '#4285f4',
    primary: {
      1: '#ccefe6',
      2: '#99dfcc',
      3: '#66ceb3',
      4: '#33be99',
      5: '#00ae80',
      6: '#008b66',
      7: '#00684d',
      8: '#004633',
      9: '#00231a',
    },
  },
  fontSizes: {
    head1: '40px',
    head2: '24px',
    body1: '20px',
    body2: '20px',
    body3: '22px',
    body4: '18px',
    body5: '17px',
    body6: '17px',
    text1: '16px',
    text2: '14px',
    text3: '14px',
    text4: '12px',
    text5: '12px',
    text6: '11px',
    memoText: '18px',
    gnb1: '10px',
    gnb2: '10px',
    date: '18px',
  },
  lineHeights: {
    head1: '117px',
    head2: '126px',
    body1: 'auto',
    body2: '117px',
    body3: '117px',
    body4: '22px',
    body5: '139px',
    body6: '139px',
    text1: '117px',
    text2: 'auto',
    text3: '117px',
    text4: 'auto',
    text5: 'auto',
    text6: '117px',
    memoText: '146px',
    gnb1: '117px',
    gnb2: '117px',
    date: '117px',
  },
};

export default theme;
